import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import { it } from '@braid/vue-formulate-i18n';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import GDriveSelector from './components/GDriveSelector.vue';
import FileUpload from './components/FileUpload.vue';
import PASS from '../api/helper/_PASS';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = /* 'http://localhost:8080' */ window.location.origin;

Vue.config.productionTip = false;
Vue.use(VueFormulate, {
	plugins: [it],
	locale: 'it',
	library: {
		drive: {
			classification: 'Upload',
			component: GDriveSelector,
		},
		filepond: {
			classification: 'Upload',
			component: FileUpload,
		},
	},
});
Vue.prototype.$axios = axios;
Vue.prototype.$SHOP_ROOT = PASS.shop_root;
Vue.use(VueCookies);
Vue.$cookies.config('30d');

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
