<template>
	<div class="hello">
		<FormulateForm
			@submit="login"
			class="login-form"
			v-model="loginForm"
			#default="{ isLoading }"
			:form-errors="formErrors"
		>
			<FormulateInput
				name="password"
				type="password"
				placeholder="Inserisci la password"
				validation="required"
				label="Password"
			/>
			<FormulateInput
				type="submit"
				:disabled="isLoading"
				:label="isLoading ? 'Caricamento...' : 'Login'"
				error-validation="submit"
			/>
			<FormulateErrors />
		</FormulateForm>
	</div>
</template>

<script>
	export default {
		name: 'Login',
		data() {
			return {
				loginForm: {},
				formErrors: [],
			};
		},
		methods: {
			async login(data) {
				try {
					this.formErrors = [];
					this.$cookies.remove('jtoken');
					let res = await this.$axios.post('/api/login', data);
					if (res.status == 200) {
						this.$cookies.set('jtoken', res.data.pass, '10d');
						this.$router.push('/');
					}
				} catch (e) {
					if (e.response && e.response.status) {
						if (e.response.status == 401) this.formErrors = ['Password errata'];
					}
				}
			},
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
