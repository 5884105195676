<template>
	<div class="bordered formulate-input">
		<label class="formulate-input-label formulate-input-label--before">
			Seleziona foto / video
		</label>
		<div class="thumb-photos">
			<div class="container" v-for="photo of context.model" :key="photo.id">
				<img
					:src="'https://drive.google.com/thumbnail?id=' + photo.id"
					@click="deleteImage"
				/>
				<div class="overlay">
					<div class="delete">
						<img
							src="https://img.icons8.com/plasticine/100/000000/delete-sign.png"
						/>
					</div>
				</div>
			</div>
		</div>

		<VueGPicker
			:clientId="
				'538713045503-fku6jr6vu7kp1u4h9gbcqi000e3vee5k.apps.googleusercontent.com'
			"
			:developerKey="'AIzaSyA_SWTIbbip0DjEUn1jCWQfsZC2maSYx3g'"
			:scope="['https://www.googleapis.com/auth/drive']"
			@authenticated="saveToken"
			:authImmediate="logged ? true : false"
			:createPicker="createPicker"
		>
			<span class="btn">Seleziona file da Google Drive</span>
		</VueGPicker>
	</div>
</template>

<script>
	import VueGPicker from '@/components/VueGPicker.vue';

	export default {
		components: {
			VueGPicker,
		},
		props: {
			context: {
				type: Object,
				required: true,
			},
		},
		computed: {
			logged: function() {
				if (this.$cookies.get('gtoken')) {
					return true;
				} else return false;
			},
		},
		methods: {
			saveToken: function(event) {
				this.$cookies.set('gtoken', event);
				// eslint-disable-next-line no-console
				//console.log('Token saved in gtoken cookie: ' + event);
			},
			createPicker: function(google, oauthToken) {
				const googleViewId = google.picker.ViewId.DOCS;
				const allView = new google.picker.DocsView(googleViewId)
					.setIncludeFolders(true)
					.setMimeTypes([
						'image/png',
						'image/jpeg',
						'image/jpg',
						'video/mp4',
						'video/mov',
						'video/quicktime',
						'video/m4v',
					])
					.setSelectFolderEnabled(false);

				const picker = new window.google.picker.PickerBuilder()
					.addView(allView)
					.setOAuthToken(oauthToken)
					.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
					.setDeveloperKey('AIzaSyA_SWTIbbip0DjEUn1jCWQfsZC2maSYx3g')
					.setCallback(data => {
						// eslint-disable-next-line no-console
						//console.log(data);
						let ids;
						switch (data.action) {
							case 'upload':
								break;
							case 'picked':
								// new array of photos won't have duplicated photos
								if (!this.context.model) this.context.model = [];

								ids = new Set(
									this.context.model.map(el => {
										return el.id;
									})
								);
								//this.context.model = [...this.context.model, ...data.docs.filter(el=> !ids.has(el.id))];
								this.context.model = this.context.model.concat(
									data.docs.filter(el => !ids.has(el.id))
								);

								// eslint-disable-next-line no-console
								//console.log(data[window.google.picker.Response.DOCUMENTS][0]);
								break;
						}
					});

				picker.build().setVisible(true);
			},
			deleteImage: function(event) {
				this.context.model = this.context.model.filter(el => {
					if (!event.target.src.includes(el.id)) return el;
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.bordered {
		background-color: rgb(233, 233, 233);
		border-radius: 15px;
		padding: 25px 25px;
		border: 3px dashed rgb(117, 117, 117);
		width: 450px;

		label {
			padding-bottom: 15px;
		}
	}

	.thumb-photos {
		display: flex;
		flex-flow: row wrap;
		padding-bottom: 20px;
		.container {
			max-width: 100px;
			padding: 0;
			margin: 10px 20px;
			position: relative;

			&:hover {
				cursor: pointer;
			}

			.overlay {
				pointer-events: none;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				height: 100%;
				width: 100%;
				opacity: 0;
				transition: 0.5s ease;
				background-color: rgba(105, 105, 105, 0.5);

				.delete {
					pointer-events: none;
					position: absolute;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					text-align: center;
					opacity: 1;
				}
			}

			&:hover .overlay {
				opacity: 1;
			}
		}

		img {
			width: 100%;
			margin-bottom: 0 !important;
		}
	}

	
</style>
