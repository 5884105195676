import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import axios from 'axios';
import nprogress from '../node_modules/nprogress';
import '../node_modules/nprogress/nprogress.css';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/login',
			name: 'home',
			component: Home,
		},
		{
			path: '/',
			name: 'choose-form',
			component: () =>
				import(/* webpackChunkName: "about" */ './views/ChooseForm.vue'),
			meta: {
				auth: true,
			},
			children: [
				{
					path: '',
					name: 'simple-form',
					component: () =>
						import(/* webpackChunkName: "about" */ './views/SimpleForm.vue'),
					meta: {
						auth: true,
					},
				},
				{
					path: 'form',
					name: 'form',
					component: () =>
						import(/* webpackChunkName: "about" */ './views/Form.vue'),
					meta: {
						auth: true,
					},
				},
			],
		},

		{
			path: '/upload',
			name: 'upload',
			component: () =>
				import(/* webpackChunkName: "about" */ './views/Upload.vue'),
		},

		// {
		//   path: '/about',
		//   name: 'about',
		//   // route level code-splitting
		//   // this generates a separate chunk (about.[hash].js) for this route
		//   // which is lazy-loaded when the route is visited.
		//   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
		// }
	],
});

router.beforeEach(async (to, from, next) => {
	nprogress.start();
	nprogress.set(0.8);
	if (to.matched.some(record => record.meta.auth)) {
		if (Vue.$cookies.get('jtoken')) {
			try {
				await axios.get('api/login');
				next();
			} catch (e) {
				next('/login');
			}
		} else next('/login');
	} else next();
});

router.afterEach(() => {
	nprogress.done();
});

export default router;
