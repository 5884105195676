import bcrypt from 'bcryptjs';

const PASS_HASHED = bcrypt.hashSync('spartan', 12);
const ROOT_SHOP =
	'https://juliatextile.myshopify.com/admin/api/2021-01/graphql.json';
const ROOT_VIDEO_SHOP =
	'https://shopify-video-production-core-originals.s3.amazonaws.com';
const API_KEY_SHOPIFY = '400e8bee95f41a490edf664fd0af2bcb';
const API_PASS_SHOPIFY = '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';
const GOOGLE_CLIENT_ID =
	'538713045503-fku6jr6vu7kp1u4h9gbcqi000e3vee5k.apps.googleusercontent.com';
const GOOGLE_CLIENT_SECRET = '2PRb_Orxiy9lefLZy7-gRdA4';
const GOOGLE_REDIRECT_URIS = [
	'http://localhost:8080',
	'https://juliatextile-upload.vercel.app',
	'http://localhost:8080/upload',
];

const passwords = {
	pass: PASS_HASHED,
	shop_root: ROOT_SHOP,
	shop_video_root: ROOT_VIDEO_SHOP,
	shopify_key: API_KEY_SHOPIFY,
	shopify_pass: API_PASS_SHOPIFY,
	g_client_id: GOOGLE_CLIENT_ID,
	g_client_secret: GOOGLE_CLIENT_SECRET,
	g_redirect_uris: GOOGLE_REDIRECT_URIS,
};

export default passwords;
