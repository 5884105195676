<template>
	<FilePond
		name="test"
		ref="pond"
		label-idle="Trascina i file qui o seleziona file..."
		:allow-multiple="true"
		accepted-file-types="image/jpeg, image/png, image/jpg, video/mp4, video/quicktime"
		:files="myFiles"
		:server="{ process, revert }"
		@addfile="startProcessFile"
		@processfiles="endProcessAllFiles"
	/>
</template>

<script>
	/* eslint-disable */
	// Import Vue FilePond
	import vueFilePond from 'vue-filepond';

	// Import FilePond styles
	import 'filepond/dist/filepond.min.css';

	// Import FilePond plugins
	// Please note that you need to install these plugins separately

	// Import image preview plugin styles
	import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

	// Import image preview and file type validation plugins
	import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
	import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

	// Create component
	const FilePond = vueFilePond(
		FilePondPluginFileValidateType,
		FilePondPluginImagePreview,
	);

	export default {
		/* eslint-disable */
		name: 'FileUpload',
		props: {
			context: {
				type: Object,
				required: true,
			},
		},
		data: function() {
			return { myFiles: [] };
		},
		methods: {
			process: async function(
				fieldName,
				file,
				metadata,
				load,
				error,
				progress,
				abort,
			) {
				this.context.model.push({
					size: file.size,
					type: file.type,
					name: file.name,
					file: file,
				});

				load(file.name);
			},
			revert(uniqueFileId, load, error) {
				this.context.model = this.context.model.filter(el => {
					return el.file.name != uniqueFileId;
				});
				load();
			},
			startProcessFile(err, file) {
				this.context.rootEmit('upload', true);
			},
			endProcessAllFiles() {
				this.context.rootEmit('upload', false);
			},
		},
		components: {
			FilePond,
		},
	};
</script>

<style lang="scss" scoped></style>
